/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스카리 님을 모시고 PEP 572, 귀도 반 로섬 BDFL 은퇴, 귀도 반 로섬 이후의 파이썬 커뮤니티(PEP 8000-8016), 파이콘, Python Software Foundation 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mail-archive.com/python-committers@python.org/msg05628.html"
  }, "Transfer of power - python-committers | The Mail Archive")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mail-archive.com/python-committers@python.org/"
  }, "python-committers | The Mail Archive")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Benevolent_dictator_for_life"
  }, "Benevolent dictator for life - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/"
  }, "PEP 0 -- Index of Python Enhancement Proposals (PEPs) | Python.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EA%B7%80%EB%8F%84_%EB%B0%98_%EB%A1%9C%EC%84%AC"
  }, "귀도 반 로섬 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/pep-0572/"
  }, "PEP 572 -- Assignment Expressions | Python.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/gvanrossum/status/1017546023227424768"
  }, "Guido van Rossum on Twitter: \"Thanks for all the support (email and Twitter). ...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/pep-8000/"
  }, "PEP 8000 -- Python Language Governance Proposal Overview | Python.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/pep-8001/"
  }, "PEP 8001 -- Python Governance Voting Process | Python.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/pep-8002/"
  }, "PEP 8002 -- Open Source Governance Survey | Python.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://discuss.python.org/t/python-governance-vote-december-2018-results/546"
  }, "Python governance vote (December 2018): Results - Committers - Discussions on Python.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/pep-8010/"
  }, "PEP 8010 -- The Technical Leader Governance Model | Python.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/pep-8011/"
  }, "PEP 8011 -- Python Governance Model Lead by Trio of Pythonistas | Python.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/pep-8012/"
  }, "PEP 8012 -- The Community Governance Model | Python.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/pep-8013/"
  }, "PEP 8013 -- The External Council Governance Model | Python.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/pep-8014/"
  }, "PEP 8014 -- The Commons Governance Model | Python.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/pep-8015/"
  }, "PEP 8015 -- Organization of the Python community | Python.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/pep-8016/"
  }, "PEP 8016 -- The Steering Council Model | Python.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/psf-landing/"
  }, "Python Software Foundation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pyfound.blogspot.com/2018/09/cpython-core-developer-sprint-2018.html"
  }, "Python Software Foundation News: CPython Core Developer Sprint 2018")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/501%28c%29_organization"
  }, "501(c) organization - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://flowingdata.com/2014/07/02/jobs-charted-by-state-and-salary/"
  }, "Jobs Charted by State and Salary | FlowingData")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pycon.sg/"
  }, "PyCon APAC 2018")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=VN5aqUcCjmw&t=0s&list=PLLpp1MOTRVCAhT6yzo2mm0DKYFO0c_ri6&index=11"
  }, "ConfConf 2016 - PyCon 2015 (Canada - Montréal ) by 김영근 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pycon-2018.python.ph/"
  }, "PyCon PH 2018")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/krisshamloo/status/737121071623634944"
  }, "Kris Shamloo on Twitter: \"Python nerds: good at programming, good at packing swag bags. ...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/pyconkorea/posts/1985474435048099?__tn__=-R"
  }, "파이콘 한국 2018 준비위원회 모집 공지 | PyCon Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://py3readiness.org/"
  }, "Python 3 Readiness - Python 3 support table for most popular Python packages")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/numpy/numpy/blob/master/doc/release/1.16.0-notes.rst"
  }, "numpy/1.16.0-notes.rst at master · numpy/numpy")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.djangoproject.com/en/2.0/releases/2.0/"
  }, "Django 2.0 release notes | Django documentation | Django")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
